import React, { useEffect, useState } from 'react';
import Article from "./Article";

const Cases = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Frss.app%2Ffeeds%2FbGMTH6JwULGE6aVo.xml');
        const data = await response.json();
        
        // Filtra artigos que possuem imagens (thumbnail ou enclosure)
        const artigosFiltrados = data.items.filter(item => item.thumbnail || item.enclosure?.link);

        // Limita a quantidade de artigos para manter o layout original e define o estado
        setArticles(artigosFiltrados.slice(0, 5));
      } catch (error) {
        console.error("Erro ao buscar artigos:", error);
      }
    };

    // Chama a função inicialmente para carregar os artigos
    fetchArticles();

    // Configura a atualização a cada 5 minutos (300000ms)
    const intervalId = setInterval(fetchArticles, 300000);

    // Limpa o intervalo quando o componente desmonta
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="px-4 md:px-14 py-20">
      <div className="md:flex gap-x-12">
        {/* Primeira seção: dois artigos lado a lado */}
        <div className="md:w-1/2">
          <Article 
            image={articles[0]?.enclosure?.link || articles[0]?.thumbnail || "default-image1.png"} 
            title={articles[0]?.title || "Título padrão 1"} 
            link={articles[0]?.link}
            className="large-image" 
          />
        </div>
        <div className="md:w-1/2">
          <Article 
            image={articles[1]?.enclosure?.link || articles[1]?.thumbnail || "default-image2.png"} 
            title={articles[1]?.title || "Título padrão 2"} 
            link={articles[1]?.link}
            className="large-image" 
          />
        </div>
      </div>
      
      {/* Segunda seção: três artigos em layout de grade */}
      <div className="grid max-sm:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-8 mt-8">
        <div>
          <Article 
            image={articles[2]?.enclosure?.link || articles[2]?.thumbnail || "default-image3.png"} 
            title={articles[2]?.title || "Título padrão 3"} 
            link={articles[2]?.link} 
            className="small-image"
          />
        </div>
        <div>
          <Article 
            image={articles[3]?.enclosure?.link || articles[3]?.thumbnail || "default-image4.png"} 
            title={articles[3]?.title || "Título padrão 4"} 
            link={articles[3]?.link}
            className="small-image" 
          />
        </div>
        <div>
          <Article 
            image={articles[4]?.enclosure?.link || articles[4]?.thumbnail || "default-image5.png"} 
            title={articles[4]?.title || "Título padrão 5"} 
            link={articles[4]?.link}
            className="small-image"
          />
        </div>
      </div>
    </section>
  );
};

export default Cases;
