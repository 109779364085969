import { useState } from "react";

const Accordion = ({ index, title, fullTitle, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="">
      <div
        className="border-t mt-10 py-4 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        {!isOpen && (
          <div className="flex items-center gap-x-10 md:gap-x-28">
            <div>{index}</div>
            <div className="text-2xl md:text-4xl text-[#263B2F] w-[250px]">
              {title}
            </div>
            <div>Clique para saber mais</div>
          </div>
        )}

        {/* Accordion Content with Animation */}
        <div
          className={`transition-all duration-500 ease-in-out overflow-hidden transform ${
            isOpen
              ? "max-h-[1000px] opacity-100 translate-y-0"
              : "max-h-0 opacity-0 -translate-y-2"
          }`}
        >
          {isOpen && (
            <div className="md:flex justify-between items-start gap-x-8 mt-4">
              <div>{index}</div>
              <div>
                <img
                  src={require("../assets/Rectangle 3.png")}
                  alt=""
                  width={300}
                  className="w-full md:min-w-[400px]"
                />
              </div>
              <div className="">
                <div className="flex">
                  <h3 className="font-semibold font-[sans-serif] text-[#263B2F] text-2xl">
                    {fullTitle}
                  </h3>
                </div>
                <p className="text-[#5A5A5A] mt-4">{description}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Accordion;
