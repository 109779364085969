import ReactStars from "react-stars";

const IndividualReview = ({ reviewText, rating }) => {
  return (
    <div>
      <p className="text-white mt-4">{reviewText}</p>
      <div className="mt-4">
        <ReactStars
          count={5}
          size={24}
          color2={"#AD9551"}
          value={rating}
          edit={false}
        />
      </div>
    </div>
  );
};

export default IndividualReview;
