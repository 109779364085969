import IndividualReview from "./IndividualReview";

const Reviews = () => {
  return (
    <div className="review-background">
      <div className="px-4 md:px-14 py-28 md:flex justify-between gap-x-20">
        <div className="md:w-1/2">
          <h2
            className="text-white md:text-3xl text-2xl font-small"
            style={{ lineHeight: 1.4 }}
          >
            "A qualidade nunca é um acidente <br /> É sempre o resultado <br /> De um esforço inteligente"
          </h2>
        </div>
        <IndividualReview
          name="Agnes Remi"
          position="VP @ Dominos"
          image="Ellipse 1.png"
          reviewText="Em mais de uma ocasião, precisei consultar um advogado tributário para entendimento de questões complexas. Sempre o nome do Renan Cavalcante me veio à mente e fui prontamente atendido. Profissional competente, dinâmico e extremamente capacitado, Renan é um advogado tributário que eu recomendo a todos."
          rating={5}
        />

        />
      </div>
    </div>
  );
};

export default Reviews;
