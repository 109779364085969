const Contact = () => {
  return (
    <section className="px-4 md:px-14 py-10 md:py-28 flex justify-center">
      <div className="text-center">
        <h2
          className="md:text-2xl lg:text-5xl text-2xl text-[#4D4D4D] mb-10"
          style={{ lineHeight: 1.4 }}
        >
          Entre em <br /> Contato
        </h2>
        <a 
          href="https://wa.me/5585988333230" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-[#AD9551] text-white px-10 py-4 font-medium font-sans rounded-lg text-lg"
        >
          Enviar mensagem no WhatsApp
        </a>
        <div className="mt-8">
          <div className="bg-black h-[1px] w-12 mx-auto"></div>
          <p className="text-[#5A5A5A] mt-6 font-sans">
            Vamos te responder o mais rápido possível
          </p>
          <p className="font-sans"></p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
