const Hero = () => {
  return (
    <div className="hero-background min-h-screen flex items-center justify-center text-center bg-cover bg-no-repeat bg-center">
      <div className="text-white p-4 md:w-[590px]">
        <h1
          className="text-4xl md:text-6xl mb-4 font-medium"
          style={{ lineHeight: 1.3 }}
        >
          Soluções Tributárias Inteligentes
        </h1>
        <p className="text-md">
          Aumentar rentabilidade protegendo seu negócio
        </p>
      </div>
    </div>
  );
};

export default Hero;
