const About = () => {
  return (
    <section
      className="px-4 md:px-14 py-20 bacgkround-about"
      style={{ marginBottom: "1rem" }} // Margem inferior aumentada para 4rem
    >
      <div className="md:w-[40%] pt-10">
        <h2
          className="text-2xl lg:text-5xl text-white font-medium"
          style={{ lineHeight: 1.4 }}
        >
          Soluções sob medida
        </h2>
        <p className="text-white mt-8 font-sans">
          Sou advogado e consultor tributário desde 2013 e atuo transformando
          complexidade tributária em vantagem competitiva para empresas. Desde
          2016, exerço a função de Conselheiro (Juiz) Titular no Contencioso
          Administrativo Tributário do Estado do Ceará, sendo um dos mais jovens
          a ocupar este cargo. Ao longo da minha carreira, tive o privilégio
          de trabalhar com algumas das maiores empresas do mundo, oferecendo
          soluções que vão além do tradicional, desenhadas para proteger e
          impulsionar negócios.
          <br />
          <br />
          Além de minha formação em Direito pela Universidade Federal do Ceará, fui destaque no MBA em
          Tributação pela FGV/RJ e me especializei em ICMS pela Faculdade CDL/CE.
          Hoje, continuo a expandir meu repertório com uma especialização em
          Gestão de Negócios pela Fundação Dom Cabral, para compreender ainda
          melhor a realidade dos clientes.
          <br />

        </p>
        <ul className="text-white mt-12 font-sans">
          <li
            className="before:content-['\2713'] before:text-xl text-xl before:mr-6 before:relative before:-left-2 before:text-[#AD9551]"
            style={{ lineHeight: 1.5 }}
          >
            Resultados
          </li>
          <li
            className="before:content-['\2713'] before:text-xl text-xl before:mr-6 before:relative before:-left-2 before:text-[#AD9551]"
            style={{ lineHeight: 1.5 }}
          >
            Proteção
          </li>
          <li
            className="before:content-['\2713'] before:text-xl text-xl before:mr-6 before:relative before:-left-2 before:text-[#AD9551]"
            style={{ lineHeight: 1.5 }}
          >
            Disponibilidade
          </li>
        </ul>
      </div>
    </section>
  );
};

export default About;
