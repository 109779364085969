import React from "react";
import './Article.css'; // Importa o arquivo CSS

export default function Article({ image, title, link, className }) {
  return (
    <div className={`article ${className}`}>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={title} className={`article-image ${className}`} />
        <div className="article-textbox flex items-center gap-x-8 mb-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="icon-md w-12"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
            />
          </svg>
          <div className="article-text">
            <h4 className="text-[#272424] text-3xl mt-6">{title}</h4>
            <p className="small-text mt-4"></p>
          </div>
        </div>
      </a>
    </div>
  );
}
