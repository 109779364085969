import { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Track scrolling
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  const handleScrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const yOffset = -145; // Offset for header
      const yPosition =
        section.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: yPosition,
        behavior: "smooth", // Smooth scroll
      });
    }
  };

  return (
    <header
      className={`px-4 md:px-14 py-6 w-full flex justify-between fixed top-0 left-0 items-center z-50 transition-all duration-300 ease-in-out ${
        hasScrolled ? "bg-gray-500 shadow-lg" : "bg-transparent"
      }`}
    >
      <div className="">
        <img
          src={require("../assets/variacao2.png")}
          alt="logo"
          width={220}
          className="max-md:w-[200px] cursor-pointer"
          onClick={() => scrollToTop}
        />
      </div>

      <div className="transition-all duration-500 ease-in-out">
        {/* Desktop Menu */}
        <ul className="hidden lg:flex text-white font-semibold font-sans">
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("about")}
          >
            Home
          </li>
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("service")}
          >
            Sobre Nós
          </li>
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("what-we-do")}
          >
            O que fazemos?
          </li>
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("cases")}
          >
            Artigos
          </li>
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("reviews")}
          >
            Clientes
          </li>
          <li
            className="px-6 cursor-pointer"
            onClick={() => handleScrollToSection("contact")}
          >
            Contato
          </li>
        </ul>

        {/* Hamburger Icon */}
        <GiHamburgerMenu
          onClick={() => setIsOpen(true)}
          className="text-3xl lg:hidden"
        />

        {/* Mobile Menu */}
        <div
          className={`fixed top-0 left-0 w-full bg-[#263B2F] text-center text-[#AD9551] z-10 transition-transform duration-500 ease-in-out transform ${
            isOpen ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
          }`}
        >
          {/* Close Icon */}
          <div className="flex justify-end p-6">
            <RxCross1 onClick={() => setIsOpen(false)} className="text-2xl" />
          </div>

          {/* Mobile Menu Links */}
          <ul className="pt-2 pb-10 font-sans">
            <li className="py-6" onClick={() => handleScrollToSection("about")}>
              Home
            </li>
            <li
              className="py-6"
              onClick={() => handleScrollToSection("service")}
            >
              Sobre nós
            </li>
            <li
              className="py-6"
              onClick={() => handleScrollToSection("what-we-do")}
            >
              O que fazemos?
            </li>
            <li className="py-6" onClick={() => handleScrollToSection("cases")}>
              Artigos
            </li>
            <li
              className="py-6"
              onClick={() => handleScrollToSection("reviews")}
            >
              Clientes
            </li>
            <li
              className="py-6"
              onClick={() => {
                handleScrollToSection("contact");
              }}
            >
              Contatos
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
