import { BsArrow90DegUp } from "react-icons/bs";
import {
  FaGooglePlusG,
  FaFacebook,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa6";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };
  return (
    <>
      <footer className="bg-[#F9F8F8] py-12 px-4 md:px-14">
        <div className="md:flex items-center justify-between">
          <div>
            <img src={require("../assets/variacao2.png")} alt="" width={220} />
          </div>
          <div>
            <ul className="md:flex font-sans">
              <li className="px-6 max-md:py-4 text-[#5A5A5A]">Rua Osvaldo Cruz, 1, Sala 1406, Fortaleza-CE</li>
              <li className="px-6 max-md:py-4 text-[#5A5A5A]">(85)98833-3230</li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="flex justify-between py-4 px-4 md:px-14">
        <div className="gap-x-24 flex items-center">
          <BsArrow90DegUp
            className="text-xl cursor-pointer"
            onClick={scrollToTop}
          />
          <p className="text-[#5A5A5A] text-xs">© 2024 — Todos os direitos reservados</p>
        </div>
        <div className="flex gap-x-4 items-center">
          <FaFacebook />
          <FaInstagram />
          <FaTwitter />
        </div>
      </div>
    </>
  );
};
export default Footer;
