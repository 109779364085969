import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Hero from "./components/Hero";
import Accordion from "./components/Accordion";
import Reviews from "./components/Reviews";
import Contact from "./components/Contact";
import Cases from "./components/Cases";
import About from "./components/About";

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <section id="about" className="py-20 px-4 md:px-14">
          <div className="md:flex justify-between gap-x-4 items-center">
            <div
              className="text-[#4D4D4D] text-4xl"
              style={{ lineHeight: 1.4 }}
            >
              Especialistas em Resultados desde 2013.
              <br /> Clientes Exclusivos.
            </div>
            <div className="md:block hidden h-60 w-[1px] bg-gray-300"></div>
            <div className="md:w-1/2">
              <p className="mt-4">
              O verdadeiro inimigo não é apenas o imposto. É a incerteza, o medo de não saber o que pode acontecer amanhã. Mas aqui está a verdade: esse medo não precisa ser seu. Porque, enquanto o caos tenta tomar conta, nós estamos do outro lado. Você pode continuar acreditando que dá para enfrentar isso sozinho, que um dia tudo vai se ajeitar... Mas sabe o que acontece com quem espera as coisas se ajeitarem sozinhas? Eles perdem. 
              </p>
              <p className="mt-4">
              Eles veem seus negócios sucumbirem lentamente, enquanto a segurança e o crescimento vão escapando pelas frestas. Nós não estamos aqui para te prometer o impossível. Sim, o caminho é cheio de desafios. Sim, há inimigos, como o caos tributário, a negligência e até a arrogância de pensar que se pode ignorar a lei sem pagar o preço. Mas estamos aqui para te ajudar a navegar por isso, lado a lado.              </p>
              <p className="mt-4">
              A nossa verdadeira missão é te devolver o controle, proteger o que é seu, e garantir que você não esteja apenas sobrevivendo nesse campo minado chamado Brasil — mas prosperando.              </p>
            </div>
          </div>
        </section>
        <div id="service">
          <About />
        </div>
        <section className="px-4 md:px-14 py-20" id="what-we-do">
          <h2 className="text-2xl md:text-4xl text-[#263B2F]">O que fazemos?</h2>
          <Accordion
            index="01"
            title="Aumento de Ativos"
            fullTitle="Aumento de Ativos"
            description="Colocamos dinheiro de volta no caixa da sua empresa com uma recuperação tributária individualizada e minuciosa. Com um planejamento tributário estratégico e altamente eficiente, reduzimos sua carga fiscal ao máximo, aproveitando todos os incentivos e regimes fiscais, para aumentar sua lucratividade com inteligência e segurança. Nossa expertise visa maximizar seus ativos e aumentar a lucratividade do seu negócio, sempre dentro dos mais elevados padrões de conformidade jurídica. Aqui, cada centavo recuperado faz a diferença para o seu crescimento. "
          />
          <Accordion
            index="02"
            title="Redução de Passivos"
            fullTitle="Redução de Passivos"
            description="Nosso compromisso é reduzir substancialmente seus passivos fiscais, atuando com rigor e precisão em defesas administrativas e judiciais. Em execuções fiscais, protegemos seu patrimônio com uma defesa robusta, impedindo bloqueios de bens e penhoras que possam comprometer a saúde financeira da sua empresa. Analisamos detalhadamente autos de infração e buscamos a reversão de cobranças indevidas, eliminando ou reduzindo penalidades. Em casos de dívidas tributárias, negociamos de forma incisiva, utilizando as ferramentas legais disponíveis, como transações tributárias e programas de parcelamento, obtendo descontos significativos e condições mais favoráveis. Nosso objetivo é assegurar que sua empresa permaneça estável, com passivos controlados e mitigados, para que seu foco continue sendo o crescimento sustentável. "
          />
          <Accordion
            index="03"
            title="Prevenção de Riscos"
            fullTitle="Prevenção de Riscos"
            description="A prevenção de riscos fiscais é um dos pilares fundamentais da nossa atuação. Implementamos programas de compliance tributário rigoroso, garantindo que sua empresa esteja em total conformidade com a legislação vigente. Com auditorias preventivas e uma gestão tributária otimizada, antecipamos e neutralizamos possíveis riscos, evitando autuações, multas e prejuízos futuros. Além disso, nossa equipe está em constante atualização com as mudanças legislativas, assegurando que suas operações fiscais sejam conduzidas de maneira eficiente e segura. A otimização dos processos internos e o acompanhamento contínuo permitem que sua empresa opere com tranquilidade, sabendo que seus riscos estão sob controle. Estamos aqui para proteger o presente e fortalecer o futuro do seu negócio. "
          />
        </section>
        <div id="cases">
          <Cases />
        </div>
        <div id="reviews">
          <Reviews />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;
